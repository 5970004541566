<template>
    <v-card tile flat>
        <v-row class="ma-0" align="center" justify="space-between">
            <v-col cols="auto">
                <v-card-title>Werkroosters</v-card-title>
            </v-col>
            <v-col cols="auto">
                <v-btn class="secondary text-none"
                       @click="createWorkSchedule">
                    <v-icon left>mdi-plus</v-icon>
                    Nieuw werkrooster
                </v-btn>
            </v-col>
        </v-row>
        <v-divider/>
        <PromiseContainer :promises.sync="promises">
            <v-list class="pa-0">
                <work-schedule-list-item v-for="workSchedule in workSchedules"
                                        :key="workSchedule.id"
                                        :work-schedule="workSchedule"
                                        @delete="deleteWorkSchedule(workSchedule.id)"/>
            </v-list>
        </PromiseContainer>
    </v-card>
</template>

<script>
import PromiseContainer from "@/components/shared/PromiseContainer.vue";
import WorkScheduleListItem from "@/components/settings/WorkScheduleListItem.vue";
import routeNames from "@/router/RouteNames";

export default {
    name: "WorkSchedulesOverview",
    components: {WorkScheduleListItem, PromiseContainer},
    data() {
        return {
            routeNames: routeNames,
            promises: [this.$store.dispatch('workScheduleModule/fetchWorkSchedules')]
        }
    },
    methods: {
        createWorkSchedule() {
            this.$router.push({name: routeNames.CREATE_WORK_SCHEDULE})
        },
      deleteWorkSchedule(workScheduleId) {
        this.promises.push(this.$store.dispatch('workScheduleModule/deleteWorkSchedule', workScheduleId))
      }
    },
    computed: {
        workSchedules() {
            return this.$store.getters["workScheduleModule/getWorkSchedules"];
        },
    }
}
</script>
