<template>
    <v-list-item two-line class="clickable">
        <v-list-item-content @click="workScheduleDetails">
            <v-list-item-title class="body-1 font-weight-medium">{{ workSchedule.name }}</v-list-item-title>
            <v-list-item-subtitle class="caption">Totaal aantal uren: {{ totalHours }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="ma-0">
            <v-btn
                    icon
                    color="primary"
                    fab
                    dark
                    @click="deleteWorkSchedule">
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
import routeNames from "@/router/RouteNames";

export default {
    name: "WorkScheduleListItem",
    props: {
        workSchedule: {
            type: Object,
            required: true,
        }
    },
    methods: {
        workScheduleDetails() {
            this.$router.push({
                name: routeNames.WORK_SCHEDULE_DETAILS,
                params: {
                    id: this.workSchedule.id,
                }
            })
        },
        deleteWorkSchedule() {
            this.$emit("delete")
        }
    },
    computed: {
        totalHours() {
            return Object.values(this.workSchedule.hoursPerDay).reduce((accumulator, hours) => accumulator + hours)
        }
    }
}
</script>
